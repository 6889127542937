import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FooterComponent } from 'projects/shared/footer/footer.component';
import { HeaderComponent } from 'projects/shared/header/header.component';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { WizardNavigationComponent } from './wizard/wizard-navigation/wizard-navigation.component';
import { WizardComponent } from './wizard/wizard.component';

import { AnswerOptionComponent } from './wizard/wizard-steps/answer-option/answer-option.component';
import { SingleChoiceQuestionStepComponent } from './wizard/wizard-steps/single-choice-question-step/single-choice-question-step.component';

import { PreApprovalPropertyAddressComponent } from './wizard/wizard-steps/pre-approval-property-address/pre-approval-property-address.component';
import { PurchasePropertyAddressComponent } from './wizard/wizard-steps/purchase-property-address/purchase-property-address.component';

import { EnumsService } from './services/enums.service';
import { MortgageApplicationService } from './services/mortgage-application.service';

import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgbActiveModal, NgbCarouselModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalService } from 'projects/shared/modal/modal.service';
import { SharedModule } from 'projects/shared/shared.module';
import { SvgIconComponentFactory } from 'projects/shared/svg-icons/svg-icon-component-factory';
import { SvgIconHostDirective } from 'projects/shared/svg-icons/svg-icon-host.directive';
import { BrowserNavigationDeactivateGuard } from './services/wizard/browser-navigation-deactivate-guard';
import { WizardFlowServiceBase } from './services/wizard/wizard-flow-service.base';
import { WizardFlowService } from './services/wizard/wizard-flow.service';
import { AddressComponent } from './wizard/address/address.component';
import { BorrowerInfoMiniComponent } from './wizard/borrower-info-mini/borrower-info-mini.component';
import { BorrowerInfoComponent } from './wizard/borrower-info/borrower-info.component';
import { WizardButtonGroupComponent } from './wizard/wizard-button-group/wizard-button-group.component';
import { AddressHistoryComponent } from './wizard/wizard-steps/address-history/address-history.component';
import { AddressStepComponent } from './wizard/wizard-steps/address-step/address-step.component';
import { CreateAccountStepComponent } from './wizard/wizard-steps/create-account-step/create-account-step.component';
import { CreateCoborrowerComponent } from './wizard/wizard-steps/create-coborrower-account/create-coborrower-account.component';
import { CreateMultipleCoBorrowersStepComponent } from './wizard/wizard-steps/create-multiple-coborrowers/create-multiple-coborrowers-step.component';
import { CurrentAddressHistoryComponent } from './wizard/wizard-steps/current-address-history/current-address-history.component';
import { CurrentAddressComponent } from './wizard/wizard-steps/current-address/current-address.component';
import { DeclarationsStepComponent } from './wizard/wizard-steps/declarations-step/declarations-step.component';
import { EmploymentDialogComponent } from './wizard/wizard-steps/employment-dialog/employment-dialog.component';
import { FileCoborrowerApplicationComponent } from './wizard/wizard-steps/file-coborrower-application/file-coborrower-application.component';
import { IncomeItemComponent } from './wizard/wizard-steps/income-item/income-item.component';
import { IncomeStepComponent } from './wizard/wizard-steps/income-step/income-step.component';
import { MaritalStatusComponent } from './wizard/wizard-steps/marital-status/marital-status.component';
import { NumberOfBorrowersComponent } from './wizard/wizard-steps/number-of-borrowers/number-of-borrowers.component';
import { OwnRentComponent } from './wizard/wizard-steps/own-rent/own-rent.component';
import { SimpleIncomeDialogComponent } from './wizard/wizard-steps/simple-income-dialog/simple-income-dialog.component';
import { SimpleMessageStepComponent } from './wizard/wizard-steps/simple-message-step/simple-message-step.component';
import { SpouseTransactionInvolvementComponent } from './wizard/wizard-steps/spouse-transaction-involvement/spouse-transaction-involvement.component';

import { IConfig, NgxMaskModule } from 'ngx-mask';
import { EnvironmentService } from 'projects/shared/services/environment.service';
import { DependentsDialogComponent } from './wizard/dependents/dependents-dialog.component';
import { WizardHorizontalNavComponent } from './wizard/wizard-horizontal-nav/wizard-horizontal-nav.component';
import { AddressHistory2Component } from './wizard/wizard-steps/address-history2/address-history2.component';
import { AssetDialogComponent } from './wizard/wizard-steps/asset-dialog/asset-dialog.component';
import { AssetItemComponent } from './wizard/wizard-steps/asset-item/asset-item.component';
import { AssetPendingNetSaleProceedsComponent } from './wizard/wizard-steps/asset-pending-net-sale-proceeds/asset-pending-net-sale-proceeds.component';
import { AssetsStepComponent } from './wizard/wizard-steps/assets-step/assets-step.component';
import { BorrowerCreditMiniComponent } from './wizard/wizard-steps/borrower-credit-mini/borrower-credit-mini.component';
import { ConsentAuthorizationStepComponent } from './wizard/wizard-steps/consent-authorization-step/consent-authorization-step.component';
import { DemographicsStepComponent } from './wizard/wizard-steps/demographics-step/demographics-step.component';
import { LiabilitiesStepComponent } from './wizard/wizard-steps/liabilities-step/liabilities-step.component';
import { LiabilityEditorComponent } from './wizard/wizard-steps/liability-editor/liability-editor.component';
import { MyInfoStepComponent } from './wizard/wizard-steps/my-info-step/my-info-step.component';
import { RealEstateOwnedDialogComponent } from './wizard/wizard-steps/reo-dialog/reo-dialog.component';
import { RealEstateOwnedItemComponent } from './wizard/wizard-steps/reo-item/reo-item.component';
import { RealEstateOwnedStepComponent } from './wizard/wizard-steps/reo-step/reo-step.component';
import { VerifyCreditStepComponent } from './wizard/wizard-steps/verify-credit-step/verify-credit-step.component';
import { WizardStepTemplateComponent } from './wizard/wizard-steps/wizard-step-template/wizard-step-template.component';
import { WizardVerticalNavComponent } from './wizard/wizard-vertical-nav/wizard-vertical-nav.component';

import { CheckEmailNoticeComponent } from './check-email-notice/check-email-notice.component';
import { ConfirmAccountComponent } from './confirm-account/confirm-account.component';
import { LoginComponent } from './login/login.component';
import { MortgageApplicationComponent } from './mortgage-application/mortgage-application.component';
import { RegistrationComponent } from './registration/registration.component';
import { AuthService } from './services/auth.service';
import { DataService } from './services/data.service';
import { NavigationService } from './services/navigation.service';
import { UtilsService } from './services/utils.service';
import { AddressHistoryCardComponent } from './wizard/address-history-card/address-history-card.component';
import { AssetCardComponent } from './wizard/asset-card/asset-card.component';
import { BorrowerCreditInquiryComponent } from './wizard/borrower-credit-inquiry/borrower-credit-inquiry.component';
import { BorrowerDetailsComponent } from './wizard/borrower-details/borrower-details.component';
import { BorrowerSummaryComponent } from './wizard/borrower-summary/borrower-summary.component';
import { LiabilityReoComponent } from './wizard/liability-reo/liability-reo.component';
import { PersonalInfoComponent } from './wizard/personal-info-card/personal-info-card.component';
import { WizardHorizontalNavStepComponent } from './wizard/wizard-horizontal-nav/wizard-horizontal-nav-step.component';
import { CreditInquiryStepComponent } from './wizard/wizard-steps/credit-inquiry-step/credit-inquiry-step.component';
import { ReviewApplicationStepComponent } from './wizard/wizard-steps/review-application-step/review-application-step.component';
import { ReviewCreditStepComponent } from './wizard/wizard-steps/review-credit-step/review-credit-step.component';
import { TypeOfLoanStepComponent } from './wizard/wizard-steps/type-of-loan-step/type-of-loan-step.component';
import { WizardVerticalNavStepComponent } from './wizard/wizard-vertical-nav/wizard-vertical-nav-step.component';

import { NgSelectModule } from '@ng-select/ng-select';
import { TextInputAutocompleteModule } from 'angular-text-input-autocomplete';
import { NgxSpinnerModule } from 'ngx-spinner';
import { GroupByPipe } from 'projects/shared/pipes/group-by.pipe';
import { SvgIconsModule } from 'projects/shared/svg-icons.module';
import { SvgUtilsService } from 'projects/shared/svg-icons/svg-utils.service';
import { ExistingUserActionSelectionDialogComponent } from './auth/existing-user-action-selection-dialog/existing-user-action-selection-dialog.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { PasswordResetEmailNoticeComponent } from './auth/password-reset-email-notice/password-reset-email-notice.component';
import { PasswordResetSendCodeDialogComponent } from './auth/password-reset-send-code-dialog/password-reset-send-code-dialog.component';
import { ResetPasswordDialogComponent } from './auth/reset-password-dialog/reset-password-dialog.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { BorrowerPortalEntryComponent } from './borrower-portal-entry/borrower-portal-entry.component';
import { CoMortgageApplicationComponent } from './co-mortgage-application/co-mortgage-application.component';
import { ErrorComponent } from './error/error.component';
import { CreateStepComponent } from './flow-editor/create-step/create-step.component';
import { ExpressionEditorComponent } from './flow-editor/expression-editor/expression-editor.component';
import { ExpressionNextStepDeciderEditorComponent } from './flow-editor/expression-next-step-decider-editor/expression-next-step-decider-editor.component';
import { FlowEditorV2Component } from './flow-editor/flow-editor-main-v2/flow-editor-main-v2.component';
import { FlowStepEditorComponent } from './flow-editor/flow-step-editor/flow-step-editor.component';
import { FlowStepPlaceholderComponent } from './flow-editor/flow-step-placeholder/flow-step-placeholder.component';
import { FlowStepComponent } from './flow-editor/flow-step/flow-step.component';
import { FlowSummaryComponent } from './flow-editor/flow-summary/flow-summary.component';
import { StepCardComponent } from './flow-editor/step-card/step-card.component';
import { StepPreviewDirective } from './flow-editor/step-preview/step-preview.directive';
import { StepSelectorComponent } from './flow-editor/step-selector/step-selector.component';
import { StepTypeCardComponent } from './flow-editor/step-type-card/step-type-card.component';
import { AddressStepFactory } from './models/wizard/config/factories/address-step.factory';
import { ChecklistsStepFactory } from './models/wizard/config/factories/checklists-step.factory';
import { CreateAccountStepFactory } from './models/wizard/config/factories/create-account-step.factory';
import { CreditScoresStepFactory } from './models/wizard/config/factories/credit-scores-step.factory';
import { ExpressionNextStepDeciderFactory } from './models/wizard/config/factories/expression-next-step-decider.factory';
import { MyInfoStepFactory } from './models/wizard/config/factories/my-info-step.factory';
import { NextStepDeciderFactory } from './models/wizard/config/factories/next-step-decider.factory';
import { SimpleNextStepDeciderFactory } from './models/wizard/config/factories/simple-next-step.decider.factory';
import { SingleChoiceQuestionStepFactory } from './models/wizard/config/factories/single-choice-question-step.factory';
import { StepConfigFactory } from './models/wizard/config/factories/step-config.factory';
import { StepTypeConfigFactory } from './models/wizard/config/factories/step-type-config.factory';
import { ChecklistsService } from './services/admin/checklists.service';
import { AssetService } from './services/asset-service';
import { CharacteristicsService } from './services/characteristics.service';
import { CreditService } from './services/credit.service';
import { FlowBuilderService } from './services/flow-builder.service';
import { GlobalConfigService } from './services/global-config.service';
import { IncomeService } from './services/income-service';
import { TurboInsuranceService } from './services/turbo-insurance.service';
import { WizardFlowConfigServiceBase } from './services/wizard/wizard-flow-config-service.base';
import { WizardFlowConfigService } from './services/wizard/wizard-flow-config.service';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { AddressHistoryDialogComponent } from './wizard/address-history-dialog/address-history-dialog.component';
import { ApplicationSubmittedComponent } from './wizard/application-submitted/application-submitted.component';
import { BorrowerAddressHistoryComponent } from './wizard/borrower-address-history/borrower-address-history.component';
import { BorrowerDeclarationsComponent } from './wizard/borrower-declarations/borrower-declarations.component';
import { BorrowerDemographicsComponent } from './wizard/borrower-demographics/borrower-demographics.component';
import { BorrowerIncomeComponent } from './wizard/borrower-income/borrower-income.component';
import { BorrowerMilitaryServiceComponent } from './wizard/borrower-military-service/borrower-military-service.component';
import { BorrowerPicker } from './wizard/borrower-picker/borrower-picker.component';
import { CharacteristicsQuantitiesDialogComponent } from './wizard/characteristics-quantities-dialog/characteristics-quantities-dialog.component';
import { CreditCheckConsentDialogComponent } from './wizard/credit-check-consent-dialog/credit-check-consent-dialog.component';
import { CreditScoreIndicatorComponent } from './wizard/credit-score-indicator/credit-score-indicator.component';
import { EmploymentHistoryCardComponent } from './wizard/employment-history-card/employment-history-card.component';
import { EmploymentHistoryDialogComponent } from './wizard/employment-history-dialog/employment-history-dialog.component';
import { LoanSummaryDialogComponent } from './wizard/loan-summary-dialog/loan-summary-dialog.component';
import { LoanSummaryComponent } from './wizard/loan-summary/loan-summary.component';
import { MilitaryServiceStepComponent } from './wizard/military-service-step/military-service-step.component';
import { PersonalInfoDialogComponent } from './wizard/personal-info-dialog/personal-info-dialog.component';
import { SubjectPropertySummaryDialogComponent } from './wizard/subject-property-summary-dialog/subject-property-summary-dialog.component';
import { SubjectPropertySummaryComponent } from './wizard/subject-property-summary/subject-property-summary.component';
import { TurboInsurancePropertyInfoEditorComponent } from './wizard/turbo-insurance-quote-dialog/turbo-insurance-property-info-editor/turbo-insurance-property-info-editor.component';
import { TurboInsuranceQuoteDialogComponent } from './wizard/turbo-insurance-quote-dialog/turbo-insurance-quote-dialog.component';
import { TurboInsuranceQuoteStatusDialogComponent } from "./wizard/turbo-insurance-quote-status-dialog/turbo-insurance-quote-status-dialog.component";
import { AssetListComponent } from './wizard/wizard-steps/asset-list/asset-list.component';
import { BorrowerCharacteristicsStepComponent } from './wizard/wizard-steps/borrower-characteristics-step/borrower-characteristics-step.component';
import { CharacteristicsPerBorrowerComponent } from './wizard/wizard-steps/borrower-characteristics-step/characteristics-per-borrower/characteristics-per-borrower.component';
import { BorrowerCreditScoresComponent } from './wizard/wizard-steps/borrower-credit-scores/borrower-credit-scores.component';
import { BorrowerDigitalIncomeComponent } from './wizard/wizard-steps/borrower-digital-income/borrower-digital-income.component';
import { BorrowerReviewApplicationComponent } from './wizard/wizard-steps/borrower-review-application/borrower-review-application.component';
import { CharacteristicsPreviewDialogComponent } from './wizard/wizard-steps/characteristics-preview-dialog/characteristics-preview-dialog.component';
import { AskQuestionComponent } from './wizard/wizard-steps/checklists-step/ask-question/ask-question.component';
import { BorrowerChecklistsComponent } from './wizard/wizard-steps/checklists-step/borrower-checklist/borrower-checklists.component';
import { ChecklistPickerComponent } from './wizard/wizard-steps/checklists-step/checklist-picker/checklist-picker.component';
import { ChecklistsStepComponent } from './wizard/wizard-steps/checklists-step/checklists-step.component';
import { CoborrowersAdressHistoryComponent } from './wizard/wizard-steps/coborrowers-address-history/coborrowers-address-history.component';
import { FlowConfigSaveDialogComponent } from './wizard/wizard-steps/config/config-save-dialog/flow-config-save-dialog.component';
import { FieldConfigEditorComponent } from './wizard/wizard-steps/config/field-config-editor/field-config-editor.component';
import { FlowListDialogComponent } from './wizard/wizard-steps/config/flow-list-dialog/flow-list-dialog.component';
import { MyInfoStepEditorComponent } from './wizard/wizard-steps/config/my-info-step-editor/my-info-step-editor.component';
import { CreditScoresStepComponent } from './wizard/wizard-steps/credit-scores-step/credit-scores-step.component';
import { CurrentAddressHistoryComponentOld } from './wizard/wizard-steps/current-address-history/current-address-history-old.component';
import { DigitalAssetsStepComponent } from './wizard/wizard-steps/digital-assets-step/digital-assets-step.component';
import { DigitalIncomeVerificationStepComponent } from './wizard/wizard-steps/digital-income-verification-step/digital-income-verification-step.component';
import { HoiStepComponent } from './wizard/wizard-steps/hoi-step/hoi-step.component';
import { LoanCharacteristicsStepComponent } from './wizard/wizard-steps/loan-characteristics-step/loan-characteristics-step.component';
import { MultiBorrowersAddressHistoryComponent } from './wizard/wizard-steps/multiborrowers-address-history-step/multiborrowers-address-history-step.component';
import { OriginatorInfoComponent } from './wizard/wizard-steps/originator-info/originator-info.component';
import { PurchaseCreditsStepComponent } from './wizard/wizard-steps/purchase-credits-step/purchase-credits-step.component';
import { ReoPropertiesComponent } from './wizard/wizard-steps/reo-properties/reo-properties.component';
import { SelectOriginatorStepComponent } from './wizard/wizard-steps/select-originator-step/select-originator-step.component';
import { SubmitApplicationStepComponent } from './wizard/wizard-steps/submit-application-step/submit-application-step.component';

import { DropdownModule } from 'primeng/dropdown';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { SliderModule } from 'primeng/slider';
import { FaviconLoaderComponent } from 'projects/shared/favicon-loader/favicon-loader.component';
import { MonthsToYearsAndMonthsPipe } from 'projects/shared/pipes/months-to-years-and-months.pipe';
import { httpInterceptorProviders } from 'projects/shared/services/http-interceptors';
import { EConsentModule } from './borrower-portal/e-consent/e-consent.module';
import { SubmitAppRedirectComponent } from './borrower-portal/submit-app-redirect/submit-app-redirect.component';
import { CreateAccountExtAuthComponent } from './create-account-external-auth/create-account-external-auth.component';
import { ExternalAuthOnlineAppRedirectComponent } from './external-auth-online-app-redirect/external-auth-online-app-redirect.component';
import { AssetsStepFactory } from './models/wizard/config/factories/assets-step.factory';
import { DemographicsStepFactory } from './models/wizard/config/factories/demographics-step.factory';
import { GatherLeadSourceStepFactory } from './models/wizard/config/factories/gather-lead-source-step.fsctory';
import { IncomeStepFactory } from './models/wizard/config/factories/income-step.factory';
import { OwnRentStepFactory } from './models/wizard/config/factories/own-rent-step.factory';
import { PricingStepFactory } from './models/wizard/config/factories/pricing-step.factory';
import { PurchaseCreditsStepFactory } from './models/wizard/config/factories/purchase-credits-step.factory';
import { ReoStepFactory } from './models/wizard/config/factories/reo-step.factory';
import { ReviewApplicationStepFactory } from './models/wizard/config/factories/review-application-step.factory';
import { VerifyCreditStepFactory } from './models/wizard/config/factories/verify-credit-step.factory';
import { LoanPurposeOptionsProvider } from './models/wizard/config/options-providers/loan-purpose-options-provider';
import { LoanPurposeOptionsProviderV2 } from './models/wizard/config/options-providers/loan-purpose-options-providerv2';
import { OptionsProviderFactory } from './models/wizard/config/options-providers/options-provider.factory';
import { PortalsCommonModule } from './portals-common/portals-common.module';
import { AgentService } from './services/agent.service';
import { ChannelService } from './services/channel.service';
import { DrawerService } from './services/drawer.service';
import { InternalContactsService } from './services/internal-contacts.service';
import { LoanService } from './services/loan.service';
import { LocalStorageService } from './services/local-storage.service';
import { MergeFieldsService } from './services/merge-fields.service';
import { MortgageService } from './services/mortgage.service';
import { NotificationService } from './services/notification.service';
import { PortalServiceFactory } from './services/portal-service.factory';
import { UserService } from './services/user.service';
import { ZipCodeService } from './services/zip-code.service';
import { ShortLinkRedirectComponent } from './short-link-redirect/short-link-redirect.component';
import { BorrowerCounselingEventComponent } from './wizard/borrower-homeownership-education/borrower-counseling-event/borrower-counseling-event.component';
import { BorrowerHomeownershipEducationComponent } from './wizard/borrower-homeownership-education/borrower-homeownership-education.component';
import { GatherLeadSourceStepComponent } from './wizard/wizard-steps/gather-lead-source-step/gather-lead-source-step.component';
import { HomeownershipEducationStepComponent } from './wizard/wizard-steps/homeownership-education-step/homeownership-education-step.component';
import { LinkLiabilitiesToReoStepComponent } from './wizard/wizard-steps/link-liabilities-to-reo-step/link-liabilities-to-reo-step.component';
import { BenefitsComponent } from './wizard/wizard-steps/pricing-step/benefits/benefits.component';
import { LiabilityCardComponent } from './wizard/wizard-steps/pricing-step/liability-card/liability-card.component';
import { PriceOptionCardComponent } from './wizard/wizard-steps/pricing-step/price-option-card/price-option-card.component';
import { PriceOptionsDialogComponent } from './wizard/wizard-steps/pricing-step/price-options-dialog/price-options-dialog.component';
import { PricingStepComponent } from './wizard/wizard-steps/pricing-step/pricing-step.component';
import { ProductPriceCardComponent } from './wizard/wizard-steps/pricing-step/product-price-card/product-price-card.component';
import { PullExistingApplicationStepComponent } from './wizard/wizard-steps/pull-existing-application-step/pull-existing-application-step.component';
import { DigitalAssetsStepFactory } from './models/wizard/config/factories/digital-assets-step.factory';
import { DigitalIncomeVerificationStepFactory } from './models/wizard/config/factories/digital-income-verification-step.factory';
import { BorrowerVerificationService } from './services/borrower-verification.service';
import { EmploymentIncomeDialogComponent } from './wizard/wizard-steps/employment-income-dialog/employment-income-dialog.component';
import { DefaultPropertyTypeOptionsProvider } from './models/wizard/config/options-providers/default-property-type-options-provider';
import { CreateMultipleCoBorrowersStepFactory } from './models/wizard/config/factories/create-multiple-coborrowers-step.factory';
import { OpenFromAdminComponent } from './portals-common/open-from-admin/open-from-admin.component';

export const options: Partial<IConfig> | (() => Partial<IConfig>) | null = null;

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderComponent,
    WizardComponent,
    WizardNavigationComponent,
    WizardButtonGroupComponent,

    SingleChoiceQuestionStepComponent,
    SimpleMessageStepComponent,
    AnswerOptionComponent,
    AddressStepComponent,
    //AddressAutocompleteComponent,
    AddressComponent,

    PreApprovalPropertyAddressComponent,
    PurchasePropertyAddressComponent,

    MaritalStatusComponent,
    SpouseTransactionInvolvementComponent,
    NumberOfBorrowersComponent,
    CreateAccountStepComponent,
    FileCoborrowerApplicationComponent,
    BorrowerInfoComponent,
    BorrowerInfoMiniComponent,
    BorrowerDeclarationsComponent,
    BorrowerHomeownershipEducationComponent,
    CreateCoborrowerComponent,
    CreateMultipleCoBorrowersStepComponent,
    CoborrowersAdressHistoryComponent,
    CurrentAddressComponent,
    AddressComponent,
    OwnRentComponent,
    DeclarationsStepComponent,
    DemographicsStepComponent,
    LoanCharacteristicsStepComponent,
    BorrowerCharacteristicsStepComponent,
    CharacteristicsPerBorrowerComponent,
    CharacteristicsPreviewDialogComponent,
    ConsentAuthorizationStepComponent,
    CurrentAddressHistoryComponent,
    CurrentAddressHistoryComponentOld,
    AddressHistoryComponent,
    AddressHistory2Component,
    MultiBorrowersAddressHistoryComponent,
    IncomeStepComponent,
    AssetsStepComponent,
    AssetItemComponent,
    RealEstateOwnedStepComponent,
    RealEstateOwnedDialogComponent,
    RealEstateOwnedItemComponent,
    ReoPropertiesComponent,
    EmploymentDialogComponent,
    MyInfoStepComponent,
    DependentsDialogComponent,
    LiabilitiesStepComponent,
    WizardStepTemplateComponent,
    LiabilityEditorComponent,
    BorrowerCreditMiniComponent,
    AssetDialogComponent,
    AssetListComponent,
    AssetPendingNetSaleProceedsComponent,
    IncomeItemComponent,
    SimpleIncomeDialogComponent,
    SvgIconHostDirective,
    LiabilityReoComponent,
    CreditInquiryStepComponent,
    BorrowerCreditInquiryComponent,
    TypeOfLoanStepComponent,
    PricingStepComponent,
    PullExistingApplicationStepComponent,
    ReviewApplicationStepComponent,
    PersonalInfoComponent,
    AddressHistoryCardComponent,
    AssetCardComponent,
    EmploymentHistoryCardComponent,
    PersonalInfoDialogComponent,
    LoanSummaryDialogComponent,
    SubjectPropertySummaryDialogComponent,
    AddressHistoryDialogComponent,
    SubmitApplicationStepComponent,
    EmploymentHistoryDialogComponent,
    DigitalAssetsStepComponent,
    BorrowerReviewApplicationComponent,
    CreditScoresStepComponent,
    BorrowerCreditScoresComponent,
    CreditScoreIndicatorComponent,
    DigitalIncomeVerificationStepComponent,
    BorrowerDigitalIncomeComponent,
    SelectOriginatorStepComponent,
    OriginatorInfoComponent,

    WizardHorizontalNavComponent,
    WizardVerticalNavComponent,
    WizardVerticalNavStepComponent,
    WizardHorizontalNavStepComponent,

    VerifyCreditStepComponent,
    ReviewCreditStepComponent,

    ConfirmAccountComponent,
    CreateAccountExtAuthComponent,
    ExternalAuthOnlineAppRedirectComponent,
    CheckEmailNoticeComponent,
    LoginComponent,
    RegistrationComponent,
    MortgageApplicationComponent,
    CoMortgageApplicationComponent,
    BorrowerDetailsComponent,
    BorrowerSummaryComponent,
    LoanSummaryComponent,
    SubjectPropertySummaryComponent,
    BorrowerAddressHistoryComponent,
    CreditCheckConsentDialogComponent,
    BorrowerDemographicsComponent,
    MilitaryServiceStepComponent,
    HoiStepComponent,
    HomeownershipEducationStepComponent,
    PurchaseCreditsStepComponent,
    TurboInsuranceQuoteDialogComponent,
    TurboInsurancePropertyInfoEditorComponent,

    BorrowerIncomeComponent,
    EmploymentIncomeDialogComponent,
    BorrowerPicker,
    BorrowerMilitaryServiceComponent,

    MyInfoStepEditorComponent,

    FlowConfigSaveDialogComponent,
    FlowListDialogComponent,

    ApplicationSubmittedComponent,

    FlowEditorV2Component,
    FlowSummaryComponent,
    FlowStepComponent,
    FlowStepPlaceholderComponent,
    StepTypeCardComponent,
    StepCardComponent,
    StepSelectorComponent,
    FlowStepEditorComponent,
    CreateStepComponent,
    ExpressionNextStepDeciderEditorComponent,

    ErrorComponent,
    BorrowerPortalEntryComponent,
    FieldConfigEditorComponent,

    TermsAndConditionsComponent,
    ExpressionEditorComponent,
    StepPreviewDirective,
    ExistingUserActionSelectionDialogComponent,
    ResetPasswordDialogComponent,
    PasswordResetSendCodeDialogComponent,
    CharacteristicsQuantitiesDialogComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    PasswordResetEmailNoticeComponent,
    TurboInsuranceQuoteStatusDialogComponent,
    ChecklistsStepComponent,
    ChecklistPickerComponent,
    AskQuestionComponent,
    BorrowerChecklistsComponent,
    GatherLeadSourceStepComponent,
    GroupByPipe,
    ProductPriceCardComponent,
    PriceOptionsDialogComponent,
    PriceOptionCardComponent,
    LinkLiabilitiesToReoStepComponent,
    LiabilityCardComponent,
    FaviconLoaderComponent,
    BenefitsComponent,
    SubmitAppRedirectComponent,
    OpenFromAdminComponent,
    ShortLinkRedirectComponent,
    BorrowerCounselingEventComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    NgbModule,
    NgbCarouselModule,
    FormsModule,
    NgxMaskModule.forRoot(),
    SharedModule,
    SvgIconsModule,
    TextInputAutocompleteModule,
    NgxSpinnerModule,
    NgSelectModule,
    EConsentModule,
    SliderModule,
    OverlayPanelModule,
    DropdownModule,
    PortalsCommonModule
  ],
  providers: [
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    EnumsService,
    MortgageApplicationService,
    SvgIconComponentFactory,
    //WizardFlowService,
    { provide: WizardFlowConfigServiceBase, useClass: WizardFlowConfigService },
    { provide: WizardFlowServiceBase, useClass: WizardFlowService },
    NgbActiveModal,
    ModalService,
    BrowserNavigationDeactivateGuard,
    EnvironmentService,
    UtilsService,
    AuthService,
    DataService,
    NavigationService,
    AssetService,
    BorrowerVerificationService,
    CreditService,
    SimpleNextStepDeciderFactory,
    ExpressionNextStepDeciderFactory,
    NextStepDeciderFactory,
    SingleChoiceQuestionStepFactory,
    AddressStepFactory,
    CreateAccountStepFactory,
    CreditScoresStepFactory,
    ChecklistsStepFactory,
    MyInfoStepFactory,
    VerifyCreditStepFactory,
    DemographicsStepFactory,
    IncomeStepFactory,
    AssetsStepFactory,
    ReoStepFactory,
    ReviewApplicationStepFactory,
    PricingStepFactory,
    OwnRentStepFactory,
    PurchaseCreditsStepFactory,
    CreateMultipleCoBorrowersStepFactory,
    GatherLeadSourceStepFactory,
    DigitalAssetsStepFactory,
    DigitalIncomeVerificationStepFactory,
    WizardFlowConfigService,
    IncomeService,
    SvgUtilsService,
    StepConfigFactory,
    StepTypeConfigFactory,
    CharacteristicsService,
    GlobalConfigService,
    FlowBuilderService,
    TurboInsuranceService,
    ChecklistsService,
    LocalStorageService,
    NotificationService,
    MergeFieldsService,
    AgentService,
    LoanService,
    UserService,
    ZipCodeService,
    MortgageService,
    ChannelService,
    InternalContactsService,
    httpInterceptorProviders,
    { provide: Window, useValue: window },
    DrawerService,
    MonthsToYearsAndMonthsPipe,
    PortalServiceFactory,
    OptionsProviderFactory,
    LoanPurposeOptionsProvider,
    DefaultPropertyTypeOptionsProvider,
    LoanPurposeOptionsProviderV2
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
