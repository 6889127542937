import { Component, HostListener, Input, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Borrower } from "../../models/borrower-model";
import { ConfigurableFieldHostComponent } from "../configurable-field-host.component";
import { formViewProvider } from "../../services/form-view.provider";

@Component({
  selector: 'borrower-info-mini',
  templateUrl: 'borrower-info-mini.component.html',
  viewProviders: [formViewProvider],
})
export class BorrowerInfoMiniComponent extends ConfigurableFieldHostComponent implements OnInit {

  @ViewChild('borrowerInfoForm') borrowerInfoForm: NgForm | undefined;

  @Input()
  customMessage: string | undefined;

  @Input()
  borrower!: Borrower;

  @Input()
  restrictMiddleNameToSingleCharacter: boolean = true;

  @Input()
  set borrowerSettings(borrowerSettings: Map<number, boolean> | undefined) {
    const settings = borrowerSettings?.get(this.borrower.borrowerId);
    if (settings != null) {
      this.mainBorrowerWillApplyForMe = settings;
    }
    this._borrowerSettings = borrowerSettings;
  }

  mainBorrowerWillApplyForMe: boolean | null = null;

  ssnPattern = { '0': { pattern: new RegExp('\\d'), symbol: 'X' } };

  private _borrowerSettings: Map<number, boolean> | undefined = undefined;

  protected isMobile: boolean = false;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.configureBasedOnScreenSize(window.innerWidth);
  }

  onMiddleNameKeyPress(event: KeyboardEvent): void {
    const char = String.fromCharCode(event.keyCode);
    const pattern = /^[a-zA-Z]$/;

    if (!pattern.test(char)) {
      event.preventDefault();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.configureBasedOnScreenSize(window.innerWidth);
  }

  setBorrowerIdContactId = (borrowerId: number, contactId: number) => {
    this.borrower.borrowerId = borrowerId;
    this.borrower.contactId = contactId;
  }

  validate = () => {
    this.borrowerInfoForm?.form.markAllAsTouched();
    if (this.borrowerInfoForm?.form.valid) {
      return true;
    }
    return false;
  }

  scrollToFirstInvalid = () => {
    let firstInvalidOneId: string = null;
    for (var key in this.borrowerInfoForm.form.controls) {
      if (this.borrowerInfoForm.form.controls.hasOwnProperty(key)) {
        if (this.borrowerInfoForm.form.controls[key].status === 'INVALID') {
          firstInvalidOneId = key;
          break;
        }
      }
    }
    if (firstInvalidOneId) {
      const elements = document.getElementsByName(firstInvalidOneId);
      let element;
      if (elements) {
        element = elements[0]
      }
      if (element) {
        setTimeout(() => {
          element.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'nearest',
          });
        }, 250);
      }
    }
  }

  onMainBorrowerWillApplyForMeChanged = (checked: boolean) => {
    this._borrowerSettings?.set(this.borrower.borrowerId, checked);
  }

  private configureBasedOnScreenSize(windowWidth: number) {
    this.isMobile = windowWidth < 768

  }
}
