<ng-template #createMultipleBorrowers>
  <ng-container *ngIf="inEditMode">
    <div class="d-flex mb-2">
      <label class="fs-6 col-sm-4 col-form-label fw-bold fs-6 editor-label">Restrict Middle Name/Initial to Single Character:</label>
      <div class="col-sm-2 form-check form-switch form-check-custom form-check-solid" style="margin-top: 8px;">
        <input class="form-check-input h-20px w-30px" type="checkbox" id="restrictMiddleNameToSingleCharacter"
          [ngModelOptions]="{standalone: true}" [(ngModel)]="step.restrictMiddleNameToSingleCharacter" />
        <label class="form-check-label" for="restrictMiddleNameToSingleCharacter">
          {{step.restrictMiddleNameToSingleCharacter ? "Yes" : "No"}}
        </label>
      </div>
    </div>
  </ng-container>
  <div class="row mb-10">
    <div class="col-md-12 fv-row mb-5" *ngFor="let coBorrower of coBorrowers; let i = index">
      <div class="card card-flush shadow-sm">
        <div class="card-header mh-30">
          <h3 class="card-title">{{coBorrower.firstName + " " + coBorrower.lastName}}</h3>
        </div>
        <div class="card-body py-2">
          <borrower-info-mini
            #borrowerInfo
            [borrower]="coBorrower"
            [fieldsToConfig]="step.fieldConfig"
            [restrictMiddleNameToSingleCharacter]="step.restrictMiddleNameToSingleCharacter"
            [borrowerSettings]="borrowerSettings">
          </borrower-info-mini>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<wizard-step-template [stepMainTemplate]="createMultipleBorrowers" [step]="step"
  [stepComponent]="this"></wizard-step-template>
