export class PortalContent {

  companyId: number | undefined;
  userId: string | undefined;
  aboutUsContent: string = '';
  referralContent: string | undefined;
  showReferralTerms: boolean | undefined;
  referralTermsContent: string | undefined;
  showPrequalLetterGeneration: boolean | undefined;
  readonly isGoogleAddressesIntegrationDisabled: boolean | null;

  constructor() {
  }
}

