import { Component, ComponentFactoryResolver, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SvgIconComponentFactory } from 'projects/shared/svg-icons/svg-icon-component-factory';
import { SvgIconHostDirective } from 'projects/shared/svg-icons/svg-icon-host.directive';
import { RealEstateOwnedItem } from '../../../models/real-estate-owned-item-model';
import { EnumsService } from '../../../services/enums.service';

@Component({
  selector: 'reo-item',
  templateUrl: 'reo-item.component.html'
})

export class RealEstateOwnedItemComponent implements OnInit {

  @ViewChild(SvgIconHostDirective, {static: true}) svgIconHost!: SvgIconHostDirective;

  constructor(private readonly _svgIconFactory: SvgIconComponentFactory,
    private _componentFactoryResolver: ComponentFactoryResolver,
    private readonly _enumsService: EnumsService) { }

  @Input()
  reoItem!: RealEstateOwnedItem;

  @Output() deleteClicked = new EventEmitter<RealEstateOwnedItem>();
  @Output() editClicked = new EventEmitter<RealEstateOwnedItem>();

  public deleteAttempted: boolean = false;

  public get isReadOnly(): boolean {
    return this.reoItem.isReadOnly;
  }

  getDeleteClickedEmitter = (): EventEmitter<RealEstateOwnedItem> => {
      return this.deleteClicked;
  }

  getEditClickedEmitter = (): EventEmitter<RealEstateOwnedItem> => {
      return this.editClicked;
  }

  onDeleteClicked = (e: any) => {
    e.cancelBubble = true;
    if(e.stopPropagation) e.stopPropagation();
    this.deleteAttempted = true;
  }

  onDeleteConfirmed = (e: any) => {
    e.cancelBubble = true;
    if(e.stopPropagation) e.stopPropagation();
    this.deleteAttempted = false;
    this.deleteClicked.emit(this.reoItem);
  }

  onDeleteAborted = (e: any) => {
    e.cancelBubble = true;
    if(e.stopPropagation) e.stopPropagation();
    this.deleteAttempted = false;
  }

  onEditClicked = () => {
      this.editClicked.emit(this.reoItem);
  }

  public get propertyTypeName(): string {
    return this._enumsService.reoPropertyTypeName(this.reoItem.propertyType!);
  }

  private get iconName(): string {
    return "duotone-home";
  }

  private loadIcon = () => {
    const iconName = this.iconName;
    if (!iconName) {
        return;
    }
    const svgIconComponent = this._svgIconFactory.getSvIconComponent(iconName);
    if (!svgIconComponent) {
        return;
    }
    const componentFactory = this._componentFactoryResolver.resolveComponentFactory(svgIconComponent);

    const viewContainerRef = this.svgIconHost.viewContainerRef;
    viewContainerRef.clear();

    viewContainerRef.createComponent(componentFactory);
  }

  ngOnInit() {
    this.loadIcon();
   }
}
