import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent  implements OnInit {

  @Output() 
  uploadClicked = new EventEmitter<File[]>();

  @Input()
  isUploading : boolean;

  public files: File[] = [];

  ngOnInit(): void {
  }

  protected onFileSelected($event: any) {
    
    let uploadedFiles = $event.target.files;
    for (const item of uploadedFiles) {
      item.progress = 0;
      this.files.push(item);
    }
  }

  protected onUploadClicked = () => {
    this.uploadClicked.emit(this.files);
  }
}

