import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { ApplicationForUser } from '../../../models/borrower-portal/application-for-user-model';
import { PortalServiceFactory } from '../../../services/portal-service.factory';
import { PortalBaseComponent } from '../../portal-base.component';
import { firstValueFrom } from 'rxjs';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { ApplicationTaskModel } from '../../../models/borrower-portal/application-task-model';
import { NotificationService } from 'projects/shared/services/notification.service';
import { getErrorMessageOrDefault } from 'projects/shared/utils/error.utils';
import { LoanDocTask } from '../../../models/tasks/loan-doc-task.model';

@Component({
  selector: 'app-add-documents-to-loan-dialog',
  templateUrl: './add-documents-to-loan-dialog.component.html',
})
export class AddDocumentsToLoanDialogComponent extends PortalBaseComponent implements OnInit {

  @Input()
  application: ApplicationForUser = new ApplicationForUser();

  protected notes: string = '';
  protected selectedBorrowerId: number;

  protected isUploading: boolean = false;

  constructor(public activeModal: NgbActiveModal, private readonly portalServiceFactory: PortalServiceFactory,
    private readonly _notifsService: NotificationService
  ) {
    super(portalServiceFactory);
  }

  ngOnInit() {
    this.selectedBorrowerId = this.application.myDetails.borrowerId;
  }

  protected onUploadClicked = async (files: any[]) => {
    let taskToAttachFilesTo: LoanDocTask = null;
    // First get the task using the portal service
    try {
      taskToAttachFilesTo = await firstValueFrom(this.portalService.getTaskForFileUpload(this.application.applicationId,
        this.selectedBorrowerId, this.notes));
    } catch (err) {
      const errorMessage = getErrorMessageOrDefault(err, {
        defaultMessage: 'Error getting task to attach files to.',
      });
      this._notifsService.showError(errorMessage, 'Error!');
      return;
    }
    // Then upload the files
    files.forEach((file, index) => {
      this.portalService.uploadDocument(taskToAttachFilesTo.loanDocTaskId, '', file).subscribe(
        (event: any) => {
          if (event.type === HttpEventType.UploadProgress) {
            this.isUploading = true;
            file.progress = Math.round(100 * event.loaded / event.total);

            if (event.loaded === event.total) {
              this.isUploading = false;
              this.activeModal.close();
            }
          } else if (event instanceof HttpResponse) {
            this.isUploading = false;
            this.activeModal.close();
          }
        },
        (err: any) => {
          const errorMessage = getErrorMessageOrDefault(err, {
            defaultMessage: 'Error uploading additional documents to loan.',
          });
          this._notifsService.showError(errorMessage, 'Error!');
          file.progress = 0;
          this.isUploading = false;
          this.activeModal.close();
        });
    });
  }
}
