<div class="dropzone" appDnd (fileDropped)="onFileSelected($event)">
  <input type="file" #fileDropRef id="fileDropRef" multiple (change)="onFileSelected($event)" />
  <img src="assets/images/dnd/ic-upload-file.svg" alt="">
  <h3>Drag and drop file here</h3>
  <h3>or</h3>
  <label for="fileDropRef">Browse for file</label>
  <br><br>
  <h6>(.txt, .pdf, .doc, .docx, .jpg, .jpeg, .png)</h6>
</div>

<file-list [files]="files" [isUploading]="isUploading"></file-list>

<button class="btn btn-success btn-sm" style="float: right;" [disabled]="files.length === 0 || isUploading" (click)="onUploadClicked()">
  {{isUploading ? "Uploading..." : "Upload"}}
</button>

  
  